<template>
  <div class="page-user-edit">
    <el-form class="h-form-2" :model="form" :rules="rules" ref="form" label-width="1rem">
      <el-form-item class="form-item-avatar" label="头像：" prop="avatar">
        <div class="avatar-uploader">
          <input ref="upload" type="file" @change="upload">
          <div v-loading="uploadLoading" class="avatar">
            <el-image :src="form.avatar" :preview-src-list="srcList" alt="">
              <img slot="error" class="image-slot" :src="defaultAvatar" alt="">
            </el-image>
            <div class="edit" @click="editAvatar">编辑</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="昵称：" prop="user_nickname">
        <el-input v-model="form.user_nickname"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="mobile">
        <el-input v-model="form.mobile" disabled></el-input>
        <a href="javascript:;" class="edit-btn" @click="editMobile">修改手机号</a>
      </el-form-item>

      <el-form-item label="性别：" prop="">
        <el-radio-group v-model="form.sex">
          <!-- 0:保密,1:男,2:女 -->
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>

      <div class="btn-wrap">
        <el-button v-loading="submitLoading" class="submit-btn" type="primary" @click="submit">保存</el-button>
      </div>
    </el-form>
    <el-dialog custom-class="h-dialog" width="4rem" title="" :show-close="false" :visible.sync="mobileVisible">
      <edit-mobile @close="mobileVisible=false" @editMobileSuccess="editMobileSuccess"></edit-mobile>
    </el-dialog>
  </div>
</template>
<script>
import editMobile from '@/components/editMobile'
import { webuploaderss, modify } from '@/api/user'
export default {
  components: {
    editMobile
  },
  data() {
    return {
      defaultAvatar: require('@/assets/images/default-avatar.png'),
      mobileVisible: false,
      rules: {},
      form: {
        uniqid: '',
        user_nickname: '',
        sex: '',
        avatar: '',
        mobile: ''
      },
      uploadLoading: false,
      submitLoading: false
    }
  },
  computed: {
    srcList() {
      return [this.form.avatar ? this.form.avatar : this.defaultAvatar]
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const { userInfo } = await this.$store.dispatch('getUserInfo')
      for (let prop in this.form) {
        this.form[prop] = userInfo[prop]
      }
    },
    editAvatar() {
      this.$refs['upload'].click()
    },
    upload(ev) {
      let file = ev.target.files[0]
      if (file) {
        this.uploadLoading = true
        let fd = new FormData()
        fd.append('file', file);
        fd.append('uniqid', this.form.uniqid);
        webuploaderss(fd).then(res => {
          this.form.avatar = res.data.filepath
          this.uploadLoading = false
        })
      }

    },
    editMobile() {
      this.mobileVisible = true
    },
    editMobileSuccess(val) {
      this.form.mobile = val
      this.mobileVisible = false
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitLoading = true
          let postData = Object.assign({}, this.form)
          delete postData.mobile
          modify(postData).then((res) => {
            this.submitLoading = false
            this.$message.success(res.msg)
            this.init()
          }).catch(err => {
            this.submitLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

  }
}
</script>
<style lang="scss" scoped>
.page-user-edit {
  width: $contentWidth;
  max-width: 100%;
  margin: 20px auto;
  background: #fff;
  padding: 60px 40px 87px;
  .h-form-2 {
    .el-form-item {
      margin-bottom: 30px;
    }
    .el-input {
      width: 280px;
      height: 50px;
    }
    .edit-btn {
      color: $mainColor;
      margin-left: 20px;
    }
    ::v-deep .el-form-item__label {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #666666;
    }
    .el-radio-group {
      padding-top: 10px;
    }
    .submit-btn {
      width: 340px;
      height: 50px;
      background: $mainColor;
      border-radius: 25px;
      font-size: 18px;
      font-weight: 500;
    }
    .avatar-uploader {
      input[type="file"] {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        opacity: 0;
      }
      .avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 50%;
        .el-image {
          width: 100%;
          height: 100%;
        }
        .edit {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 30px;
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
    .form-item-avatar {
      ::v-deep .el-form-item__label {
        height: 120px;
        line-height: 120px;
      }
    }
  }
}
</style>